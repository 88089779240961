<template>
  <header>
    <div class="logo">
      <img src="/W Logo.png" alt="Weldri">
      <a href="https://weldri.com/"><h1>Weldri - Descuentos El Salvador</h1></a>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderCustom"
}
</script>

<style scoped>
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #242433;
  color: #ffffff;
}
header h1{
  margin: 0;
  font-size: 0.8rem;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
}

.logo img {
  max-width: 50px;
  margin-right: 1rem;
}

.logo h1 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
}

.logo a {
  display: inline-block;
}
</style>