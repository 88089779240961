<template>
  <div class="carousel-container" v-show="showCarrousel">
    <div class="carousel-track" :style="trackStyles">
      <div class="carousel-slide" v-for="(image, index) in images" :key="index">
        <img :src="image.url" alt="carousel slide" class="carousel-image" v-on:error="handleImageError">
        <div class="carousel-caption">
          <h3>{{ formatPrice(image.price) }}{{image.currency}}</h3>
          <h4>{{ image.dateUploadFormat }}</h4>
        </div>
      </div>
    </div>
    <div class="carousel-controls">
      <button @click="previousSlide" class="button-slide" style="color: #000;" >&lt;</button>
      <button @click="nextSlide" class="button-slide"  style="color: #000;">&gt;</button>
    </div>
  </div>
  <!-- <div class="carousel-container" v-show="showCarrousel">

   <Galleria :value="images" :responsiveOptions="responsiveOptions" :numVisible="5" :autoPlay="false" :transitionInterval="2000"  @touchstart.passive="prevent"  @touchmove.passive="prevent"   >
     <template #item="slotProps">
       <img :src="slotProps.item.url" :alt="slotProps.item.dateUploadFormat" style="width: 100%!important; max-width: 100%!important;  max-height: 1000px!important;" />
     </template>
     <template #thumbnail="slotProps">
       <img :src="slotProps.item.url" :alt="slotProps.item.dateUploadFormat" />
     </template>
   </Galleria>
</div>-->
</template>

<script>
import  "../assets/Carrousel.css";
//import Galleria from 'primevue/galleria';
import axios from "axios";


export default {
  data() {
    return {
      currentIndex: 0,
      intervalId: null,
      images: [
      ],
      showCarrousel: false,
      responsiveOptions: [
        {
          breakpoint: '1024px',
          numVisible: 3
        },
        {
          breakpoint: '768px',
          numVisible: 2
        },
        {
          breakpoint: '560px',
          numVisible: 1
        }
      ]
    }
  },
  components: {
    //Galleria
  },
  computed: {
    trackStyles() {
      return {
        transform: `translateX(-${this.currentIndex * 100}%)`
      }
    },
    fullScreenIcon() {
      return this.isFullScreen() ? 'pi pi-window-minimize' : 'pi pi-window-maximize';
    }


  },
  mounted() {
    this.startInterval();
    axios
        .post("https://api.todociber.dev/api/v1/productDetails/listImages", {
          id: this.$route.params.id,
        })
        .then((response) => {
          this.images = response.data.nameFiles;
          if (this.images.length > 0) {
            this.showCarrousel = true;
          }
        })
        .catch(error => {
          console.log(error);
        });
  },
  methods: {
    onThumbnailButtonClick() {
      this.galleriaRef.toggleThumbnailPanel();
    },
    toggleFullScreen() {
      if (this.isFullScreen()) {
        this.exitFullScreen();
      }
      else {
        this.requestFullScreen();
      }
    },
    isFullScreen() {
      return document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement;
    },
    exitFullScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      }
      else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
      else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
    requestFullScreen() {
      const element = document.documentElement;

      if (element.requestFullscreen) {
        element.requestFullscreen();
      }
      else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      }
      else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      }
      else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    },




    handleImageError(event) {
      event.target.src = 'Error-404.jpg';
    },
    formatPrice(price) {
      const options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      };
      return Number(price).toLocaleString('en', options);
    },
    startInterval() {
      this.intervalId = setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.images.length;
      }, 5000);
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
      clearInterval(this.intervalId);
      this.startInterval();
    },
    previousSlide() {
      this.currentIndex = this.currentIndex === 0 ? this.images.length - 1 : this.currentIndex - 1;
      clearInterval(this.intervalId);
      this.startInterval();
    }
  },
  beforeUnmount() {
    clearInterval(this.intervalId);
  }
}
</script>
