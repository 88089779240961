<template>
  <div>
    <h2>Preguntas frecuentes para usuarios</h2>
    <ul>
      <li v-for="question in userQuestions" :key="question.id">
        <button @click="toggleQuestion(question.id)">{{ question.title }}</button>
        <p v-if="expandedQuestions[question.id]">{{ question.answer }}</p>
      </li>
    </ul>
  </div>

  <div>
    <h2>Preguntas frecuentes Tiendas</h2>
    <ul>
      <li v-for="question in faqWebsIndexadas" :key="question.id">
        <button @click="toggleQuestion(question.id)">{{ question.title }}</button>
        <p v-if="expandedQuestions[question.id]">{{ question.answer }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "FaqSection",
  data() {
    return {
      userQuestions : [
        {
          id: 1,
          title: "¿Cómo puedo buscar un producto en tu sitio web?",
          answer: "Puedes buscar productos ingresando el nombre del producto en la barra de búsqueda ubicada en la parte superior de la página. También puedes utilizar los filtros disponibles en la página de búsqueda para encontrar productos por rango de precio y por tienda."
        },
        {
          id: 2,
          title: "¿Cómo funciona el historial de precios?",
          answer: "El historial de precios muestra la evolución del precio de un producto a lo largo del tiempo. Puedes ver cómo ha variado el precio de un producto en diferentes tiendas en línea y determinar cuándo es el mejor momento para comprar. No podemos asegurar que tengamos registrados todos los cambios de precios del producto o que los precios que se muestran sean los correctos. Si encuentras algún error, por favor contáctanos"
        },
        {
          id: 3,
          title: "¿Cómo puedo utilizar los filtros de búsqueda?",
          answer: "Puedes utilizar los filtros de búsqueda para limitar tus resultados de búsqueda por rango de precio y por tienda. Para aplicar un filtro, simplemente haz clic en la opción correspondiente en la página de búsqueda."
        },
        {
          id: 4,
          title: "¿Cómo puedo comprar un producto en tu sitio web?",
          answer: "Nuestro sitio web no procesa transacciones ni ventas, sino que se dedica a indexar productos de otras tiendas en línea. Para comprar un producto, haz clic en el enlace 'Comprar en la tienda' en la página de detalles del producto, lo que te llevará a la tienda en línea donde podrás realizar la compra."
        },
        {
          id: 5,
          title: "¿Cómo puedo saber si un producto está disponible?",
          answer: "El inventario de los productos lo gestiona la tienda en línea correspondiente, por lo que es importante que verifiques la disponibilidad en la página de detalles del producto en la tienda en línea donde se vende."
        }
      ],// Lista de preguntas para usuarios
      faqWebsIndexadas : [
        {
          id: 6,
          title: "¿Cómo aparece mi tienda en tu sitio web?",
          answer: "Nuestro sitio web indexa tiendas en línea y productos de manera automática utilizando técnicas de web crawling y scraping. Si tu tienda en línea cumple con los criterios necesarios, es posible que tu tienda y tus productos aparezcan en nuestro sitio web."
        },
        {
          id: 7,
          title: "¿Qué información de mi tienda y productos se muestra en tu sitio web?",
          answer: "En nuestro sitio web mostramos información pública de tu tienda en línea, incluyendo el nombre, la descripción y la URL de tu tienda. En cuanto a los productos, mostramos información pública como el nombre, la descripción y el precio."
        },
        {
          id: 8,
          title: "¿Puedo solicitar la eliminación de mi tienda o productos de tu sitio web?",
          answer: "Sí, si deseas que retiremos tu tienda o productos de nuestro sitio web, por favor contáctanos a través del formulario de contacto que se encuentra en nuestro sitio web."
        },
        {
          id: 9,
          title: "¿Cómo puedo actualizar la información de mis productos en tu sitio web?",
          answer: "Actualmente no permitimos la actualización manual de la información de los productos en nuestro sitio web. Sin embargo, la información que mostramos es obtenida de manera automática de tu tienda en línea. Si necesitas actualizar la información de tus productos, te recomendamos actualizarla en tu sitio web y esperar a que nuestros algoritmos la detecten y actualicen en nuestro sitio web."
        },
        {
          id: 10,
          title: "¿Cómo protegen la privacidad y seguridad de mi tienda y productos?",
          answer: "En nuestro sitio web sólo utilizamos información pública de tu tienda y productos, y la utilizamos de manera limitada para generar nuestro historial de precios. No almacenamos información confidencial de tus ventas o de tus clientes. Además, tomamos medidas de seguridad para proteger nuestro sitio web de ataques y vulnerabilidades."
        }
      ],
      expandedQuestions: {} // Estado de preguntas expandidas
    }
  },
  methods: {
    toggleQuestion(questionId) {
      this.expandedQuestions[questionId] = !this.expandedQuestions[questionId]
    }
  },
}
</script>

<style scoped>
button {
  background-color: transparent;
  border: none;
  color: #007aff; /* Cambia el color del texto */
  cursor: pointer;
  font-size: 1em; /* Cambia el tamaño del texto */
  text-decoration: underline; /* Agrega un subrayado */
}

/* Estilos para el botón cuando se coloca el cursor encima */
button:hover {
  opacity: 0.8; /* Reduce la opacidad del botón */
}
</style>