<template>
  <button v-if="showBackButton" @click="goBack" class="back-btn">Volver</button>
  <button v-else @click="goHome" class="back-btn">Inicio</button>

  <div class="container  justify-content-center">
    <div class="row justify-content-center detailsProduct">



      <div class="product-info">
        <h2 class="text-title">{{ product.product_name }}</h2>
        <p class="text-description">{{ product.product_description }}</p>
        <div class="other-info">
          <div>
            <p><b>Precio: </b>${{ formatPrice(product.product_value) }} {{ product.product_currency }}</p>
            <p><b>Tienda: </b>{{ webName }}</p>
            <p><b>SKU: </b>{{ product.product_internal_id }}</p>
            <p><b>Marca: </b>{{ product.product_brand }}</p>
            <p><b>Disponible: </b>{{ product.product_available }} </p>
          </div>


        </div>
        <img :src="product.product_image" :alt="product.product_name" class="image-details" v-on:error="handleImageError">
        <a :href="product.product_url" target="_blank" class="store-bar-btn" >Ver en tienda</a>
      </div>

    </div>
 <canvas id="myChart"></canvas>
    <div class="product-info-details">
    <p><b>Promedio:</b>${{formatPrice(pricesData.averagePrice)}}</p>
    <p><b>Último precio:</b> ${{formatPrice(pricesData.lastPrice)}}</p>
    <p><b>Precio Máximo:</b> ${{formatPrice(pricesData.maxPrice)}}</p>
    <p><b>Precio Minimo:</b> ${{formatPrice(pricesData.minPrice)}}</p>
      </div>
    </div>
  <google-ads
      ad-client="ca-pub-2095499803385415"
      ad-slot="5"
      ad-format="auto"
  ></google-ads>
  <div>
    <CarouselDetails2></CarouselDetails2>
  </div>
</template>

<script>
import '../assets/ProductDetails.css';
import axios from "axios";
import Chart from 'chart.js/auto';
import CarouselDetails2 from "@/components/CarouselDetails2.vue";

export default {
  name: "ProductDetails",
  components: {
    CarouselDetails2,
  },
  props: {
    productId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      product: '',
      webName: '',
      priceHistoryPrices: [],
      priceHistoryDates: [],
      pricesData: [],
    };
  },
  methods: {
    renderMyGrafic(ctx,priceHistoryPrices, priceHistoryDates){
      Chart.defaults.elements.line.borderWidth = 10;
      Chart.defaults.elements.line.fill = false;
      Chart.defaults.elements.line.tension = 0;
      Chart.defaults.elements.line.borderColor = 'rgba(227,0,0,0.55)';
      Chart.defaults.elements.line.backgroundColor = 'rgba(227,0,0,0.55)';
      Chart.defaults.elements.line.pointHoverBackgroundColor = 'rgb(109,252,13)';
      Chart.defaults.elements.line.pointHoverBorderColor = 'rgb(109,252,13)';
      Chart.defaults.elements.line.pointHoverBorderWidth = 10;
      Chart.defaults.elements.line.pointBackgroundColor = 'rgb(109,252,13)';
      Chart.defaults.elements.line.pointBorderColor = 'rgb(109,252,13)';
      Chart.defaults.elements.line.pointBorderWidth = 10;
      Chart.defaults.elements.line.pointStyle = 'triangle';
      Chart.defaults.elements.point.radius = 10;
      Chart.defaults.elements.point.hoverRadius = 20;
      Chart.defaults.elements.point.borderWidth = 1;
      Chart.defaults.elements.point.hoverBorderWidth = 2;
      Chart.defaults.elements.point.backgroundColor = 'rgb(109,252,13)';
      Chart.defaults.elements.point.borderColor = 'rgb(109,252,13)';
      Chart.defaults.elements.point.hoverBackgroundColor = 'rgb(109,252,13)';
      Chart.defaults.elements.point.hoverBorderColor = 'rgb(109,252,13)';
      Chart.defaults.elements.point.hitRadius = 10;
      Chart.defaults.elements.point.hoverHitRadius = 10;
      Chart.defaults.elements.point.hoverRadius = 10;
      Chart.defaults.elements.point.radius = 10;
      Chart.defaults.elements.point.rotation = 0;
      Chart.defaults.elements.point.pointStyle = 'circle';
      //Custom Hover element for the chart
      Chart.defaults.hover.mode = 'nearest';
      Chart.defaults.hover.intersect = true;
      Chart.defaults.hover.animationDuration = 0;
      Chart.defaults.hover.backgroundColor = 'rgba(227,0,0,0.55)';
      Chart.defaults.hover.borderColor = 'rgba(227,0,0,0.55)';
      Chart.defaults.hover.borderWidth = 10;
      Chart.defaults.hover.borderRadius = 10;
      Chart.defaults.hover.titleFont = {
        size: 25,
      };
      //Tooltip
      Chart.defaults.plugins.tooltip.enabled = true;
      Chart.defaults.plugins.tooltip.mode = 'index';
      Chart.defaults.plugins.tooltip.intersect = false;
      Chart.defaults.plugins.tooltip.backgroundColor = 'rgba(0,0,0,0.52)';
      Chart.defaults.plugins.tooltip.titleFont = {
        size: 15,
      };
      Chart.defaults.plugins.tooltip.titleColor = 'rgb(255,255,255)';
      Chart.defaults.plugins.tooltip.titleAlign = 'center';
      Chart.defaults.plugins.tooltip.titleSpacing = 2;
      Chart.defaults.plugins.tooltip.titleMarginBottom = 15;
      Chart.defaults.plugins.tooltip.bodyFont = {
        size: 15,
      };





      const myChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: priceHistoryDates,
          datasets: [{
            label: 'Precio Historico',
            data: priceHistoryPrices,
            backgroundColor: 'rgb(248,215,9)',
            borderColor: 'rgba(227,0,0,0.55)',

          }]
        },
        options: {
          responsive: true,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          },
          transitions: {
            show: {
              animations: {
                x: {
                  from: 0
                },
                y: {
                  from: 0
                }
              }
            },
          }
        }
      });

      myChart.render();
    },
    handleImageError(event) {
      event.target.src = 'Error-404.jpg';
    },
    goBack() {
      window.history.back();
    },
    goHome() {
      // redirigir a la página de inicio de tu aplicación
      window.location.href = '/';
    },
    isFromThisWebsite(url) {
      return url.startsWith(window.location.origin);
    },
    formatPrice(price) {
      const options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      };
      return Number(price).toLocaleString('en', options);
    },
  },
  mounted() {
    const ctx = document.getElementById('myChart').getContext('2d');
    axios
        .post("https://api.todociber.dev/api/v1/productDetails", {
          id: this.$route.params.id,
          second_id: this.$route.params.name,
        })
        .then((response) => {
          this.product = response.data.product;
          this.priceHistoryPrices = response.data.priceHistoryPrices;
          this.priceHistoryDates = response.data.priceHistoryDates;
          this.pricesData = response.data.pricesData;
          this.renderMyGrafic(ctx,this.priceHistoryPrices, this.priceHistoryDates)
          this.webName = response.data.product.weldri_link.weldri_web.name;
          document.title = 'Weldri - '+ response.data.product.product_name;
          const descriptionMeta = document.querySelector('meta[name="description"]');
          if (descriptionMeta && (response.data.product.product_description !== null || response.data.product.product_description !== undefined || response.data.product.product_description !== '' )  ) {
            descriptionMeta.setAttribute('content', response.data.product.product_description);
          }
        })
        .catch((error) => {
          this.$router.push({ name: 'Error', params: { code: error.response.status.toString() } });
        });
  },
  computed: {
    showBackButton() {
      return window.history.length > 1 && this.isFromThisWebsite(document.referrer);
    }
  },
};

</script>