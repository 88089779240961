<template>
  <HeaderCustom></HeaderCustom>
  <div class="container">
    <router-view/>
  </div>
  <FooterCustom/>
</template>

<script>
import FooterCustom from './components/Footer.vue'
import HeaderCustom from './components/Header.vue'


export default {
  name: 'App',
  components: {
    FooterCustom,
    HeaderCustom
  }
}

</script>