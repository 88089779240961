<template>
  <footer class="footer">
    <div class="container-lg" >
      <div class="row">
        <div class="col-12 col-md-6">
          <ul class="list-inline">
            <ol><router-link :to="{ name: 'Faqs', params: { } }">FAQ</router-link></ol>
            <ol><label class="footer-text">Contactanos:</label><a href = "mailto: admin@weldri.com">admin@weldri.com</a></ol>
          </ul>
        </div>
        <div class="col-12 col-md-6">
          <ul class="list-inline social-icons">

          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterCustom'
}
</script>

<style>
footer {
  background-color: #242433;
  max-width: 100%;
  display: flex;
}

.social-icons li {
  margin-right: 15px;
}

.social-icons i {
  font-size: 24px;
  color: #666;
  transition: color 0.3s ease-in-out;
}
a{
  color: #fff;
  text-decoration: none;

}
.footer-text{
  color: #fff;
  text-decoration: none;
  font-weight: bold;
}
a.hover{
  color: #007bff;
}

.social-icons i:hover {
  color: #007bff;
}
html, body, #app {
  max-width: 100%;
  width: auto;
  height: 100%;
  margin: 0;

}
</style>