<template>
  <div class="search-bar"  @keyup.enter.prevent="searchAction" >
    <div class="search-bar-row">
      <input type="text" placeholder="Buscar producto..." v-model="query" @input="searchProducts" class="search-bar-input">
      <button @click="searchAction" class="search-bar-btn g-recaptcha" data-sitekey="6Ld_HgklAAAAAC0nm5wUynMLc09jdvqRvlAiEH4B"
              data-callback='click'
              data-action='click'>Buscar</button>
    </div>
    <div class="input-container">
      <div class="p-inputgroup flex-1">
        <span class="p-inputgroup-addon">$</span>
        <InputNumber placeholder="Precio minimo" :maxFractionDigits="0" locale="en-US" :min="0"  v-model="minPrice"  @input="searchProducts"  />
        <span class="p-inputgroup-addon">.00</span>
      </div>
    </div>
    <div class="input-container">
      <div class="p-inputgroup flex-1">
        <span class="p-inputgroup-addon">$</span>
        <InputNumber placeholder="Precio máximo" v-model="maxPrice" locale="en-US"  :maxFractionDigits="0"   @input="searchProducts" />
        <span class="p-inputgroup-addon">.00</span>
      </div>
    </div>
    <div class="input-container">
      <Dropdown v-model="selectedProvider" :options="providersArray" :optionLabel="'name'" :option-value="'code'" :filter="true"  class="custom-dropdown" placeholder="Escoge una tienda" @change="clearSelectionIfNecessary"></Dropdown>
    </div>

    <!--<div class="advanced-options" v-if="showAdvancedOptions">
      <div class="checkbox-container" v-if="showAdvancedOptions">
        <label for="stock">Mostrar solo productos en stock:</label>
        <input type="checkbox" id="stock" v-model="inStock" @change="searchProducts">
      </div>
      <div class="input-container" v-if="showAdvancedOptions">
        <label for="internal-id">ID interno SKU:</label>
        <input type="text" id="internal-id" v-model="internalId" @input="searchProducts">
      </div>
      <div class="input-container" v-if="showAdvancedOptions">
        <label for="url">URL del producto:</label>
        <input type="text" id="url" v-model="url" @input="searchProducts">
      </div>
    </div>
    <button class="show-more-options" @click="showAdvancedOptions = !showAdvancedOptions">{{ showAdvancedOptions ? 'Ocultar opciones avanzadas' : 'Mostrar más opciones' }}</button>-->
    <button class="show-more-options" @click="resetSearch()">Resetear</button>
  </div>
</template>

<script>
import '../assets/SearchComplete.css';

import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';

//theme
import "primevue/resources/themes/lara-light-indigo/theme.css";

//core
import "primevue/resources/primevue.min.css";

//icons
import "primeicons/primeicons.css";

import Cookies from 'js-cookie';
export default {
  name: 'SearchBar',
  components: {
    InputNumber,
    Dropdown
  },
  data() {
    return {
      minPrice: 0,
      maxPrice: 999,
      leftThumbPosition: 0,
      query: '',
      selectedProvider: '',
      providers: {
        1: 'Omnisport',
        2: 'Aeon',
        3: 'Kayfa',
        4: 'KPC',
        5: 'Zona Digital',
        6: 'IntelMax',
        7: 'La  Curacao',
        8: 'RadioShack',
        9: 'Prado',
        10: 'Siman',
        11: 'Tropigas',
        12: 'Raf',
        13: 'Walmart',
        14: 'Random Computer',
        15: 'XHT',
        16: 'Epa',
        18: 'Vidri',
        19: 'Office Depot',
        20: 'Steren',
        },
      providersArray: [
        {name: 'Selecciona una tienda', code: '', label: 'Selecciona una tienda'},
        {name: 'Omnisport', code: '1', label: 'Omnisport'},
        {name: 'Aeon', code: '2', label: 'Aeon'},
        {name: 'Kayfa', code: '3', label: 'Kayfa'},
        {name: 'KPC', code: '4', label: 'KPC'},
        {name: 'Zona Digital', code: '5', label: 'Zona Digital'},
        {name: 'IntelMax', code: '6', label: 'IntelMax'},
        {name: 'La  Curacao', code: '7', label: 'La  Curacao'},
        {name: 'RadioShack', code: '8', label: 'RadioShack'},
        {name: 'Prado', code: '9', label: 'Prado'},
        {name: 'Siman', code: '10', label: 'Siman'},
        {name: 'Tropigas', code: '11', label: 'Tropigas'},
        {name: 'Raf', code: '12', label: 'Raf'},
        {name: 'Walmart', code: '13', label: 'Walmart'},
        {name: 'Random Computer', code: '14', label: 'Random Computer'},
        {name: 'XHT', code: '15', label: 'XHT'},
        {name: 'Epa', code: '16', label: 'Epa'},
        {name: 'Vidri', code: '18', label: 'Vidri'},
        {name: 'Office Depot', code: '19', label: 'Office Depot'},
        {name: 'Steren', code: '20', label: 'Steren'},

      ],
      inStock: false,
      internalId: '',
      url: '',
      showAdvancedOptions: false,
      searchObject: {},
    }
  },
  methods: {
    sortProviders() {
      this.providersArray.sort((a, b) => (a.label > b.label ? 1 : -1));
      this.providersArray.unshift({
        name: 'Escoge una tienda',
        code: '',
        label: 'Escoge una tienda',
      });
    },
    clearSelectionIfNecessary() {
      if (this.selectedProvider === '') {
        this.selectedProvider = null;
      }
    },
    searchProducts() {
      // Aquí iría la lógica para buscar los productos según los criterios de búsqueda
      // Podrías emitir un evento o llamar a una función en el componente padre para mostrar los resultados

      this.updateCookies();
    },
    updateCookies(){
      this.searchObject.query = this.query ? this.query : '';
      this.searchObject.minPrice = this.minPrice ? this.minPrice : 0;
      this.searchObject.maxPrice = this.maxPrice ? this.maxPrice : 999;
      this.searchObject.selectedProvider = this.selectedProvider ? this.selectedProvider : '';
      this.searchObject.inStock = this.inStock ? this.inStock : '';
      this.searchObject.internalId = this.internalId ? this.internalId : '';
      this.searchObject.url = this.url ? this.url : '';
      this.searchObject.showAdvancedOptions = this.showAdvancedOptions ? this.showAdvancedOptions : false;
      Cookies.set('searchObject', JSON.stringify(this.searchObject));
    },
    setParamsURL(){
        this.$router.push({ query: { q: this.query, minPrice: this.minPrice, maxPrice: this.maxPrice, selectedProvider: this.selectedProvider } });
    },
    clearParamsURL(){
      this.$router.push({ query: { q: '', minPrice: '', maxPrice: '', selectedProvider: '' } });
      const newUrl = window.location.pathname;
      window.history.pushState(null, '', newUrl);
    },
    resetSearch(){
     this.query = '';
      this.minPrice = 0;
      this.maxPrice = 999;
      this.selectedProvider = '';
      this.updateCookies();
      this.clearParamsURL();
      this.searchAction();
    },
    searchAction() {
      this.validateMinPriceInput();
      this.validateMaxPriceInput();
      this.updateCookies();
      this.setParamsURL();
      this.$emit('search', this.searchObject);
    },
    validateMinPriceInput() {
      const minPrice = parseInt(this.minPrice)

      if (isNaN(minPrice)) {
        this.minPrice = ''
      } else {
        this.minPrice = minPrice.toString()
      }
      if (this.minPrice >= parseInt(this.maxPrice)) {
        this.minPrice = parseInt(this.maxPrice) - 1
      }
    },
    validateMaxPriceInput() {
      const maxPrice = parseInt(this.maxPrice)

      if (isNaN(maxPrice)) {
        this.maxPrice = ''
      } else {
        this.maxPrice = maxPrice.toString()
      }

      if (this.maxPrice <= parseInt(this.minPrice)) {
        this.maxPrice = parseInt(this.minPrice) + 1
      }

    },
  },
  created() {
    this.sortProviders();
  },
  mounted() {
  if(this.$route.query.q){
    this.query = this.$route.query.q;
    if(this.$route.query.minPrice){
      this.minPrice = this.$route.query.minPrice;
    }else{
      this.minPrice = 0;
    }
    if(this.$route.query.maxPrice){
      this.maxPrice = this.$route.query.maxPrice;
    }else{
      this.maxPrice = 999;
    }
    if(this.$route.query.selectedProvider){
      this.selectedProvider = this.$route.query.selectedProvider;
    }else{
      this.selectedProvider = '';
    }
    if(this.$route.query.inStock){
      this.inStock = this.$route.query.inStock;
    }else{
      this.inStock = false;
    }
    if(this.$route.query.internalId){
      this.internalId = this.$route.query.internalId;
    }else{
      this.internalId = '';
    }
    if(this.$route.query.url){
      this.url = this.$route.query.url;
    }else{
      this.url = '';
    }
    if(this.$route.query.showAdvancedOptions){
      this.showAdvancedOptions = this.$route.query.showAdvancedOptions;
    }else{
      this.showAdvancedOptions = false;
    }
    this.searchAction();
  }else if (Cookies.get('searchObject')) {
    /*console.log('cookie');
    this.searchObject = JSON.parse(Cookies.get('searchObject'));

    if (this.searchObject.minPrice === '' || this.searchObject.minPrice === undefined) {
      this.searchObject.minPrice = 0
    }
    console.log(this.searchObject.maxPrice);
    if (this.searchObject.maxPrice === '' || this.searchObject.maxPrice === undefined) {
      this.searchObject.maxPrice = 999
    }
    if (this.searchObject.selectedProvider === '' || this.searchObject.selectedProvider === undefined) {
      this.searchObject.selectedProvider = ''
    }
      this.query = this.searchObject.query;
      this.minPrice = this.searchObject.minPrice;
      this.maxPrice = this.searchObject.maxPrice;
      this.selectedProvider = this.searchObject.selectedProvider;
      this.inStock = this.searchObject.inStock;
      this.internalId = this.searchObject.internalId;
      this.url = this.searchObject.url;
      this.showAdvancedOptions = this.searchObject.showAdvancedOptions;

      this.searchAction();
      this.setParamsURL();*/
    }else{
      this.searchObject = {
        query: '',
        minPrice: 0,
        maxPrice: 999,
        selectedProvider: '',
        inStock: false,
        internalId: '',
        url: '',
    }
  }
  }
}
</script>

<style scoped>


</style>