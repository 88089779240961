<template>
  <div>
    <div class="search-box">
      <search-box v-on:search="onSearch"></search-box>
    </div>
    <div class="product-list">
      <div v-if="products.length === 0 && loading === false" class="no-results">
        <h3>No se encontraron resultados. &#128546;</h3>
      </div>
      <div v-if="defaultList" class="no-results">
        <h2>Aquí tienes una muestra de los árticulos actualizados recientemente.!</h2>
      </div>
      <div v-for="(product, index) in products" :key="index" class="product-list-details"  >
        <img :src="product.product_image" :alt="product.product_name" v-on:error="handleImageError" >
        <div style="flex: 3; margin-left: 1rem">
          <h3>{{ product.product_name }}</h3>
          <p>{{ sliceText(product.product_description) }}</p>
          <p><b>Tienda: </b>{{ product.weldri_link.weldri_web.name }}</p>
          <!--transform procut.product_value to format 0.00   -->

          <p><b>Precio: </b>{{ formatPrice(product.product_value)}} {{ product.product_currency }}</p>
          <router-link :to="{ name: 'productDetails', params: { id: product.weldri_links_id , name: product.product_clean_id } }" class="details-bar-btn">Ver detalles</router-link>
        </div>
      </div>
      <div v-if="loading" class="loading" >Loading...</div>
    </div>
    <button v-show="products.length >3" class="btn-float" style="z-index: 1" :class="{ show: showScrollTop }" @click="scrollTop">
      <span class="mdi mdi-arrow-up" ></span>
    </button>
  </div>
</template>

<script>
import '../assets/ProductList.css';
import axios from "axios";
import SearchBox from "./SearchComplete.vue";


export default {
  props: {
    searchQuery: {
      type: String,
      required: true
    }
  },
  name: "ProductList",
  components: {
    SearchBox,
  },
  data() {
    return {
      products: [],
      page: 1,
      loading: false,
      defaultList: true,
      nextPage: true,
      totalPages: null,
      showScrollTop: false,
      totalProducts: 0,
      continueLoading: true,
    };
  },
  methods: {
    sliceText(text) {
      return text.slice(0, 100);
    },
    formatPrice(price) {
      const options = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      };
      return Number(price).toLocaleString('en', options);
    },
    handleImageError(event) {
      event.target.src = 'Error-404.jpg';
    },
    onSearch(searchText) {
      //console.log('Search Text = '+searchText.query);
      this.searchProducts(searchText);
    },
    scrollTop() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    searchProducts(query) {
      this.products = [];
      this.page = 1;
      this.totalPages = null;
      this.query = query;
      this.continueLoading = true;
      this.loadNextPage();
    },
    loadNextPage() {

      if (this.continueLoading === false) {
        return 0;
      }else if (this.loading) {
        return 0;
      }else{
        this.loading = true;
        this.defaultList = false;
        axios
            .post("https://api.todociber.dev/api/v1/productSearch", {
              search: this.query.query,
              price: {
                min: this.query.minPrice,
                max: this.query.maxPrice,
              },
              web_id: this.query.selectedProvider,
              url: this.query.url,
              internalID: this.query.internalId,
              page: this.page,
            })
            .then((response) => {
              if(response.data.code === 100){
                this.products = this.products.concat(response.data.products.data);
                this.page++;
                this.totalPages = response.data.products.last_page;
                this.nextPage = response.data.products.next_page_url;
                //console.log("next page " + this.nextPage);
                if (this.nextPage == null) {
                  this.continueLoading = false;
                }
              }else{
                this.continueLoading = false;
              }

            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.loading = false;
            });
      }

    },
    loadProducts() {
      this.loading = true;
      axios
          .post("https://api.todociber.dev/api/v1/productLast", {})
          .then((response) => {
            if(response.data.code === 100){
              this.products = this.products.concat(response.data.products.data);
              this.page++;
              this.totalPages = response.data.products.last_page;
              this.nextPage = response.data.products.next_page_url;
              this.continueLoading = false;
            }else{
              this.continueLoading = false;
            }

          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
    },

  },
  mounted() {
    if (! this.$route.query.q) {
      this.loadProducts(this.searchQuery);
      this.defaultList = true;
    }else {
      this.defaultList = false;
    }

    window.addEventListener("scroll", () => {
      if (
          window.innerHeight + window.scrollY >= document.body.offsetHeight &&
          !this.loading
      ) {
        this.loadNextPage();
      }
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > 300) {
        this.showScrollTop = true;
      } else {
        this.showScrollTop = false;
      }
    });
  },
};

</script>