import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router';
import App from './App.vue'
import '@mdi/font/css/materialdesignicons.css'
import ProductList from './components/ProductList.vue';
import ProductDetails from './components/ProductDetails.vue';
import SearchComplete from "./components/SearchComplete.vue";
import CarouselDetails2 from "@/components/CarouselDetails2.vue";
import FaqSection from "@/components/Faqs/FaqSection.vue";
import PrimeVue from 'primevue/config';

const routes = [
    {
        path: '/',
        name: 'home',
        component: ProductList,
    },
    {
        path: '/products/:id/:name',
        name: 'productDetails',
        component: ProductDetails,
    },
    {
        path: '/products/images/:id',
        name: 'productDetailsImages',
        component: CarouselDetails2,
    },
    {
        path: '/searchAdvance',
        name: 'SearchAdvance',
        component: SearchComplete,
    },
    {
        path: '/faq',
        name: 'Faqs',
        component: FaqSection,
    },
    {
        path: '/error/:code',
        name: 'Error',
        component: () => import('@/components/Error.vue')
    },
];

const router = createRouter({
    history: createWebHistory('/'),
    routes,
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || 'Weldri - El Salvador';
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description || 'Historico de descuentos y promociones en El Salvador');
    document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.keywords || 'Descuentos, Promociones, El Salvador, Ofertas, Ofertas El Salvador, Ofertas en El Salvador, Ofertas en San Salvador, Ofertas en Santa Ana, Ofertas en Soyapango, Ofertas en San Miguel');
    next();
});

createApp(App).use(router).use(PrimeVue).mount('#app');

